.toast-container {
    &.toast-top-right {
        top: 76px;
    }

    .toast-close-button {
        font-size: 18px;
        margin-right: 4px;
    }

    .toast {
        align-items: center;
        background-image: none !important;
        border-radius: $border-radius;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
        display: flex;
        opacity: 1 !important;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        }

        &:before {
            color: #FFFFFF;
            float: left;
            font-family: FontAwesome;
            font-size: 25px;
            line-height: 20px;
            margin: auto 0 auto 15px;
        }

        .toast-content {
            padding: 15px;
        }

        .toaster-icon {
            display: none;
        }

        .toast-message {
            p {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.toast-danger, &.toast-error {
            background-image: none !important;

            &:before {
                content: "\f0e7";
            }
        }

        &.toast-warning {
            background-image: none !important;

            &:before {
                content: "\f071";
            }
        }

        &.toast-info {
            background-image: none !important;

            &:before {
                content: "\f05a";
            }
        }

        &.toast-success {
            background-image: none !important;

            &:before {
                content: "\f00C";
            }
        }
    }
}

.toast-error, .toast-container .toast-error:before, .toast-danger, .toast-container .toast-danger:before, .bg-danger {
    @include themify($themes) {
        background-color: themed('danger') !important;
        color: themed('dangerTextColor') !important;
    }
}

.toast-warning, .toast-container .toast-warning:before, .bg-warning {
    @include themify($themes) {
        background-color: themed('warning') !important;
        color: themed('warningTextColor') !important;
    }
}

.toast-success, .toast-container .toast-success:before, .bg-success {
    @include themify($themes) {
        background-color: themed('success') !important;
        color: themed('successTextColor') !important;
    }
}

.toast-info, .toast-container .toast-info:before, .bg-info {
    @include themify($themes) {
        background-color: themed('info') !important;
        color: themed('infoTextColor') !important;
    }
}
