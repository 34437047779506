html {
    font-size: 14px;
}

body {
    min-width: 1010px;

    &.layout_frontend {
        @media (prefers-color-scheme: dark) {
            background-color: $darkDarkBlue2;
        }
        @media (prefers-color-scheme: light) {
            background-color: $white;
        }
        @include themify($themes) {
            background-color: themed('layoutFrontendColor');
            color: themed('headingColor');
        }
    }

    @include themify($themes) {
        background-color: themed('backgroundColor');
        color: themed('textColor');
    }

    &.full-width:not(.layout_frontend) {
        .container {
            min-width: 980px;
            width: 90%;
        }
    }
}

.container {
    margin: 0 auto;
    max-width: none !important;
    padding: 0;
    width: 980px;
}

.page-header, .secondary-header {
    margin-bottom: 0.5rem;
    padding-bottom: 0.6rem;
    @include themify($themes) {
        border-bottom: 1px solid themed('separator');
    }

    &:not(.text-danger) {
        h1, h2, h3, h4 {
            margin: 0;
            @include themify($themes) {
                color: themed('headingColor');
            }
        }
    }
}

.secondary-header, .spaced-header {
    margin-top: 4rem;
}

img.logo {
    display: block;
    height: 43px;
    margin: 0 auto;
    width: 284px;
    @include themify($themes) {
        content: url('../images/logo-' + themed('logoSuffix') + '@2x.png');
    }
} 

.page-content {
    margin-top: 20px;
}

.footer {
    margin-top: 40px;
    padding: 40px 0 40px 0;
    @include themify($themes) {
        border-top: 1px solid themed('separator');
    }
}

hr, .dropdown-divider {
    @include themify($themes) {
        border-top: 1px solid themed('separatorHr');
    }
}

.min-height-fix {
    min-height: 1px;
}

.overflow-hidden {
    overflow: hidden;
}

.cursor-move {
    cursor: move !important;
}


h1, h2, h3, h4, h5 {
    @include themify($themes) {
        color: themed('headingColor');
    }

    small {
        font-size: 80%;
    }

    &.spaced-header {
        @include themify($themes) {
            color: themed('headingColor');
        }
    }
}

a {
    @include themify($themes) {
        color: themed('linkColor');
    }

    &.text-body {
        @include themify($themes) {
            color: themed('headingColor') !important;
            font-weight: themed('linkWeight');
        }
    }
}

a:hover {
    @include themify($themes) {
        color: themed('linkColorHover');
    }
}

code {
    @include themify($themes) {
        color: themed('codeColor');
    }
}

.fa-icon-above-input {
    height: 1.5em;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-strike {
    text-decoration: line-through;
}

.font-weight-semibold {
    font-weight: 600;
}

.btn:focus, .swal2-popup .swal2-actions button:focus, .btn.focus, .swal2-popup .swal2-actions button.focus, .form-control:focus {
    @include themify($themes) {
        box-shadow: 0 0 0 0.2rem themed('focus');
    }
}

/* Override Bootstrap theming */

.bg-primary {
    @include themify($themes) {
        background-color: themed('bgPrimaryColor');
    }
}

.bg-light {
    @include themify($themes) {
        background-color: themed('bgLightColor') !important;
    }
}

.border-primary {
    @include themify($themes) {
        border-color: themed('borderPrimaryColor') !important;
    }
}

.border-warning {
    @include themify($themes) {
        border-color: themed('warning') !important;
    }
}

.border-danger {
    @include themify($themes) {
        border-color: themed('danger') !important;
    }
}

.border-info {
    @include themify($themes) {
        border-color: themed('info') !important;
    }
}

.text-success {
    @include themify($themes) {
        color: themed('success') !important;
    }
    
    & > h1,h2,h3,h4 {
        @include themify($themes) {
            color: themed('success') !important;
        }
    }
}

.text-warning {
    @include themify($themes) {
        color: themed('warning') !important;
    }

    & > h1,h2,h3,h4 {
        @include themify($themes) {
            color: themed('warning') !important;
        }
    }
}

.text-danger {
    &:not(.dropdown-item) {
        @include themify($themes) {
            color: themed('danger') !important;
        }

        & > h1,h2,h3,h4 {
            @include themify($themes) {
                color: themed('danger') !important;
            }
        }
    }
}

.text-muted {
    @include themify($themes) {
        color: themed('textMuted') !important;
    }
}
