.password-wrapper {
    min-width: 0;
    white-space: pre-wrap;
    word-break: break-all;
}

.password-row {
    min-width: 0;
}

.password-letter {
    @include themify($themes) {
        color: themed('pwLetter');
    }
}

.password-number {
    @include themify($themes) {
        color: themed('pwNumber');
    }
}

.password-special {
    @include themify($themes) {
        color: themed('pwSpecial');
    }
}

app-vault-groupings, app-org-vault-groupings, .groupings {
    .card {
        #search {
            margin-bottom: 1rem;
            @include themify($themes) {
                background-color: themed('inputBackgroundColor');
                border-color: themed('inputBorderColor');
                color: themed('inputTextColor');
            }

            &::placeholder {
                @include themify($themes) {
                    color: themed('inputPlaceholderColor');
                }
            }
        }

        h3 {
            font-weight: normal;
            text-transform: uppercase;
            @include themify($themes) {
                color: themed('textMuted');
            }
        }

        ul:last-child {
            margin-bottom: 0;
        }

        .card-body a {
            @include themify($themes) {
                color: themed('headingColor');
                font-weight: themed('linkWeight');
            }

            &:hover {
                &.text-muted {
                    @include themify($themes) {
                        color: themed('iconHover') !important;
                    }
                }
            }
        }

        .show-active {
            display: none;
        }

        li {
            > .fa, > div > .fa {
                cursor: pointer;
            }
        }

        li.active {
            > .show-active, > div .show-active {
                display: inline;
            }
        }

        li.active {
            > a:first-of-type, > div a:first-of-type {
                font-weight: bold;
                @include themify($themes) {
                    color: themed('linkColor');
                }
            }

            > .fa, > div > .fa {
                @include themify($themes) {
                    color: themed('linkColor');
                }
            }
        }
    }
}

app-password-generator {
    #lengthRange {
        width: 100%;
    }

    .card-password {
        .card-body {
            @include themify($themes) {
                background: themed('foregroundColor');
            }
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            font-family: $font-family-monospace;
            font-size: $font-size-lg;
            justify-content: center;
            text-align: center;
        }
    }
}

app-password-generator-history {
    .list-group-item {
        line-height: 1;
        @include themify($themes) {
            background: themed('backgroundColor');
        }

        .password {
            font-family: $font-family-monospace;
        }
    }
}

app-import {
    textarea {
        height: 150px;
    }
}

app-avatar {
    img {
        @extend .rounded;
    }
}

app-user-billing {
    .progress {
        height: 20px;
        
        .progress-bar {
            min-width: 50px;
        }
    }
}


/* Register Layout Page - Exempt from themify */
body.theme_light_force {
    background-color: #ECF0F5;
    a, .btn-link {
        color: #175DDC;
        &:hover {
            color: #104097;
        }
    }

    .btn-outline-secondary {
        color: #6c757d;
        &:hover {
            color: #212529;
        }
    }

    .text-muted {
        color: #6C757D !important;
    }

    .card, .card-body {
        background-color: #FFFFFF;
        border-color: rgba(0,0,0,.125);
    }

    .form-control {
        background-color: #FBFBFB;
        border: 1px solid #CED4DA;
        color: #495057;
    }

    label {
        color: #333333;
        font-weight: 600;
        &.small {
            font-weight: 400;
        }
    }

    hr {
        border-top: 1px solid rgba(0,0,0,.1)
    }
    .progress {
        background-color: #E9ECEF;
    }
    .bg-primary {
        background-color: #175DDC !important;
    }
    .bg-success {
        background-color: #00A65A !important;
    }
    .bg-warning {
        background-color: #BF7E16 !important;
    }
    .bg-danger {
        background-color: #DD4B39 !important;
    }

    .layout {
        &.enterprise2 {
            header {
                background: #175DDC;
                color: #CED4DA;

                &:before {
                    background: #175DDC;
                    content: "";
                    height: 416px;
                    left: 0;
                    position: absolute;
                    top: -76px;
                    transform: skewY(-3deg);
                    width: 100%;
                    z-index: -1;
                }
                img.logo {
                    margin: 12px 0 0;
                    max-width: 284px;
                    height: 57px;
                    width: 284px;
                }
            }

            h2 {
                color: #FFFFFF;
                font-size: 1.8rem;
                margin: 100px 0 150px 0;
            }

            p {
                font-size: 1.4rem;
                margin: 20px 0 40px 0;

                &:before {
                    content: "/";
                    padding-right: 12px;
                }
                &:not(.highlight) {
                    &:before {
                        color: #1252A3;
                    }
                }

                b {
                    &:after {
                        content: "⟶";
                        font-size: 2rem;
                        padding-left: 6px;
                    }
                }
            }

            blockquote {
                font-size: 1.4rem;
                margin: 20px 0 0 0;
                padding-right: 40px;
            }
        }
    }
}
